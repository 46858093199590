export const state = {
  neighborhood: undefined, // need to set default neighborhood for non instay guest
  cityGems: {
    category: 'all',
    tags: new Set([])
  },
}

export const getters = {
  neighborhood(state) {
    return state.neighborhood
  },
  cityGemsFilters(state) {
    return state.cityGems
  },
  cityGemsCategory(state) {
    return state.cityGems.category
  },
  tags(state) {
    return state.cityGems.tags
  },
}

export const mutations = {
  SET_NEIGHBORHOOD_FILTER(state, neighborhood) {
    state.neighborhood = neighborhood
  },
  SET_CITYGEMS_FILTER(state, filters) {
    state.cityGems = filters
  },
  SET_TAGS_FILTER(state, tags) {
    state.cityGems.tags = tags
  },
  TOGGLE_TAG(state, tag) {
    state.cityGems.tags.has(tag)
      ? state.cityGems.tags.delete(tag)
      : state.cityGems.tags.add(tag)

  }
}

export const actions = {
  async setNeighborhood({ commit, dispatch }, neighborhood = undefined) {
    commit('SET_NEIGHBORHOOD_FILTER', neighborhood)
    dispatch('resetTagFilter')
  },

  async setCityGemsCategory({ commit }, category) {
    commit('SET_CITYGEMS_FILTER', { ...state.cityGems, category })
  },

  resetTagFilter({ commit, dispatch }) {
    dispatch('setCityGemsCategory', 'all')
    commit('SET_TAGS_FILTER', new Set())
  },
  async setDefaultNeighborhood({ commit, dispatch, rootGetters }) {
    dispatch('resetTagFilter')

    if (rootGetters['auth/currentProfile']?.property) {
      commit('SET_NEIGHBORHOOD_FILTER', rootGetters['neighborhoods/randCurrent'])
    } else {
      commit('SET_NEIGHBORHOOD_FILTER', rootGetters['neighborhoods/randAll'])
    }
  },
  toggleTag({ dispatch, commit }, tag: string) {
    dispatch('setCityGemsCategory', 'all')
    commit('TOGGLE_TAG', tag)
  },
  setNeighborhoodBySlug({ commit, dispatch, rootGetters }, neighborhoodSlug) {
    if (neighborhoodSlug === state.neighborhood?.slug ||
      !neighborhoodSlug ||
      !rootGetters['neighborhoods/allSlugs'].includes(neighborhoodSlug)
    ) {
      return
    }

    dispatch('resetTagFilter')
    const neighborhoods = rootGetters['neighborhoods/all']
    const selectedNeighborhood = neighborhoods.find((neighborhood) => neighborhood.slug.toUpperCase() === neighborhoodSlug.toUpperCase())
    commit('SET_NEIGHBORHOOD_FILTER', selectedNeighborhood)
  }
}
