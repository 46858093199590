import { useCms } from '@/use/use-cms'
import { getRandomElements } from '../../utils/helpers'

export const state = {
  all: undefined,
  current: undefined,
}

export const getters = {
  all(state) {
    return state.all
  },
  current(state) {
    return state.current
  },
  randCurrent(state) {
    return getRandomElements(state.current)
  },
  randAll(state) {
    return getRandomElements(state.all)
  },
  allSlugs(state) {
    return state.all.map(neighborhood => neighborhood.slug)
  },
}

export const mutations = {
  SET_ALL_NEIGHBORHOODS(state, neighborhoodsList) {
    state.all = neighborhoodsList
  },
  SET_CURRENT_NEIGHBORHOODS(state, currentNeighborhoods) {
    state.current = currentNeighborhoods
  },
}

export const actions = {
  async fetchAndSetNeighborhoods({ commit, rootGetters }) {
    const { fetchNeighborhoods } = useCms()
    const neighborhoodsList = await fetchNeighborhoods()

    commit('SET_ALL_NEIGHBORHOODS', neighborhoodsList)

    const acronym = rootGetters['auth/currentProfile'].property?.acronym
    let currentNeighborhoods
    if (acronym) {
      currentNeighborhoods = neighborhoodsList.filter(neighborhood =>
        neighborhood.properties.find(property => property.acronym === acronym)
      )
      commit('SET_CURRENT_NEIGHBORHOODS', currentNeighborhoods)
    }
  },
}
